import { createRef, FC, useCallback, useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  Hidden,
  IconButton,
  lighten,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  SwipeableDrawer,
  Tab,
  Tabs,
  Toolbar,
  useScrollTrigger,
} from '@mui/material';
import logo from '../../assets/images/logo.png';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Bed as BedIcon,
  DirectionsCar as DirectionsCarIcon,
  ForkLeft as ForkLeftIcon,
  Home as HomeIcon,
  Menu as MenuIcon,
} from '@mui/icons-material';
import { scrollTo } from '../../utils/window';
import NederlandsIcon from '../icons/Nederlands';
import EnglishIcon from '../icons/English';
import { leToBr } from '../../utils';

const links = [
  {
    key: 'homepage',
    label: 'navbar.homepage',
    icon: <HomeIcon />,
  },
  {
    key: 'bnb',
    label: 'navbar.bnb',
    icon: <BedIcon />,
  },
  {
    key: 'car-rental',
    label: 'navbar.car-rental',
    icon: <DirectionsCarIcon />,
  },
  {
    key: 'special-renault-4',
    label: 'navbar.special-renault-4',
    icon: <DirectionsCarIcon />,
  },
  {
    key: 'routes',
    label: 'navbar.routes',
    icon: <ForkLeftIcon />,
  },
  // {
  //   key: 'video',
  //   label: 'navbar.video-productions',
  //   icon: <VideoIcon />,
  // },
];

export interface NavBarProps {
  scrolling: boolean;
  setScrolling: (scrolling: boolean) => void;
  onActiveSectionChange?: (sectionId: string) => void;
  activeSectionId?: string;
}

const NavBar: FC<NavBarProps> = ({
  scrolling,
  setScrolling,
  onActiveSectionChange,
  activeSectionId,
}) => {
  const { t, i18n } = useTranslation();
  const appBarRef = createRef<HTMLDivElement>();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
  });

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [selectedSection, setSelectedSection] = useState<string>('homepage');
  const [languageAnchorEl, setLanguageAnchorEl] = useState<HTMLElement | null>(
    null
  );
  const languagePopupOpen = Boolean(languageAnchorEl);

  const toggleDrawer = useCallback(() => setOpenDrawer((v) => !v), []);
  const scrollToSection = useCallback(
    async (sectionId: string) => {
      const targetElement = document.getElementById(sectionId);
      if (targetElement) {
        setScrolling(true);
        await scrollTo({
          top: targetElement.offsetTop - (appBarRef.current?.offsetHeight || 0),
          behavior: 'smooth',
        });
        setScrolling(false);
      }
    },
    [appBarRef, setScrolling]
  );

  return (
    <AppBar
      ref={appBarRef}
      position="fixed"
      color={trigger ? 'primary' : 'transparent'}
      elevation={trigger ? 2 : 0}
      sx={{
        transition: 'all .250s ease-out',
      }}
    >
      <Toolbar
        sx={(theme) => ({
          py: 3.5,
          [theme.breakpoints.up('lg')]: {
            px: 12.5,
          },
        })}
      >
        <Link
          component={RouterLink}
          to="/"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <img src={logo} alt="Cinéroutes" />
        </Link>

        <Stack
          direction="row"
          sx={(theme) => ({
            ml: 4,
            [theme.breakpoints.down('md')]: { ml: 'auto', mr: 4 },
            [theme.breakpoints.down('sm')]: { mr: 2 },
          })}
          spacing={{ xs: 2, sm: 4 }}
        >
          <Button
            sx={{
              p: 0,
              minWidth: 0,
              overflow: 'hidden',
              borderRadius: i18n.language === 'nl' ? 4 : 0,
              border:
                i18n.language === 'nl'
                  ? '3px solid white'
                  : '3px solid transparent',
              transition: 'all .25s ease-in-out',
              boxSizing: 'border-box',
            }}
            onClick={() => i18n.changeLanguage('nl')}
          >
            <NederlandsIcon />
          </Button>
          <Button
            sx={{
              p: 0,
              minWidth: 0,
              overflow: 'hidden',
              borderRadius: i18n.language === 'en' ? 4 : 0,
              border:
                i18n.language === 'en'
                  ? '3px solid white'
                  : '3px solid transparent',
              transition: 'all .25s ease-in-out',
              boxSizing: 'border-box',
            }}
            onClick={() => i18n.changeLanguage('en')}
          >
            <EnglishIcon />
          </Button>
        </Stack>

        <Stack
          direction="row"
          spacing={3}
          sx={(theme) => ({
            ml: 'auto',
            [theme.breakpoints.down('md')]: {
              display: 'none',
            },
          })}
        >
          <Tabs
            value={activeSectionId || selectedSection}
            onChange={(_, v) =>
              onActiveSectionChange
                ? onActiveSectionChange(v)
                : setSelectedSection(v)
            }
            sx={{
              '& .MuiTabs-flexContainer': {
                height: '100%',
                alignItems: 'center',
              },
              '& .MuiTabs-indicator': {
                height: 4,
              },
            }}
          >
            {links.map(({ key, label }) => (
              <Tab
                key={key}
                value={key}
                sx={(theme) => ({
                  position: 'relative',
                  textDecoration: 'none',
                  color: 'white',
                  fontSize: theme.typography.pxToRem(18),
                  display: 'flex',
                  alignItems: 'center',
                  '&.Mui-selected': {
                    color: 'white',
                  },
                })}
                onClick={() => {
                  if (key === 'video') {
                    const a = document.createElement('a');
                    a.href = 'https://www.cineroutesvideoproduction.com';
                    a.target = '_blank';
                    document.body.appendChild(a);
                    a.click();
                  } else {
                    scrollToSection(key);
                  }
                }}
                label={leToBr(t(label))}
              />
            ))}
          </Tabs>
          <Button
            component="a"
            variant="contained"
            href="#contact"
            onClick={(e: any) => {
              e.preventDefault();
              scrollToSection('contact');
            }}
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              borderRadius: 1000,
              backgroundColor: 'white',
              color: theme.palette.primary.main,
              fontSize: theme.typography.pxToRem(20),
              py: 1.5,
              px: 4,
              '&:hover': {
                background: lighten(theme.palette.primary.main, 0.5),
              },
            })}
          >
            {t('navbar.contact')}
          </Button>
        </Stack>

        <Menu
          anchorEl={languageAnchorEl}
          open={languagePopupOpen}
          onClose={() => setLanguageAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={() => i18n.changeLanguage('nl')}>
            <NederlandsIcon sx={{ mr: 2 }} />
            {t('lang.nederlands')}
          </MenuItem>
          <MenuItem onClick={() => i18n.changeLanguage('en')}>
            <EnglishIcon sx={{ mr: 2 }} />
            {t('lang.english')}
          </MenuItem>
        </Menu>

        <Hidden mdUp>
          <IconButton onClick={toggleDrawer}>
            <MenuIcon sx={{ fontSize: '2rem' }} />
          </IconButton>

          <SwipeableDrawer
            anchor="right"
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
            onOpen={() => setOpenDrawer(true)}
          >
            <Box
              sx={{
                width: 'clamp(0px, 75vw, 300px)',
              }}
              role="presentation"
              onClick={() => setOpenDrawer(false)}
              onKeyDown={() => setOpenDrawer(false)}
            >
              <List>
                {links.map(
                  ({ key, label, icon }) => (
                    <ListItem
                      key={key}
                      disablePadding
                      sx={
                        activeSectionId === key
                          ? (theme) => ({
                              color: lighten(theme.palette.primary.main, 0.2),
                              position: 'relative',
                              '&:before': {
                                content: '""',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                height: '100%',
                                width: 3,
                                backgroundColor: lighten(
                                  theme.palette.primary.main,
                                  0.2
                                ),
                              },
                            })
                          : undefined
                      }
                    >
                      <ListItemButton
                        onClick={() => {
                          onActiveSectionChange
                            ? onActiveSectionChange(key)
                            : setSelectedSection(key);
                          scrollToSection(key);
                          setOpenDrawer(false);
                        }}
                      >
                        <ListItemIcon sx={{ color: 'inherit' }}>
                          {icon}
                        </ListItemIcon>
                        <ListItemText primary={t(label)} />
                      </ListItemButton>
                    </ListItem>
                  )
                  //   {/* <ListItemButton
                  //     onClick={() => {
                  //       if (key === 'video') {
                  //         const a = document.createElement('a');
                  //         a.href = 'https://www.cineroutesvideoproduction.com';
                  //         a.target = '_blank';
                  //         document.body.appendChild(a);
                  //         a.click();
                  //       } else {
                  //         onActiveSectionChange
                  //           ? onActiveSectionChange(key)
                  //           : setSelectedSection(key);
                  //         scrollToSection(key);
                  //         setOpenDrawer(false);
                  //       }
                  //     }}
                  //   >
                  //     <ListItemIcon sx={{ color: 'inherit' }}>
                  //       {icon}
                  //     </ListItemIcon>
                  //     <ListItemText primary={t(label)} />
                  //   </ListItemButton>
                  // </ListItem> */}
                )}
              </List>
            </Box>
          </SwipeableDrawer>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
