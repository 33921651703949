import { ReactNode, Fragment } from 'react';
import emailjs from '@emailjs/browser';

export function leToBr(value: string): ReactNode {
  return value.split('\n').map((line, i, a) => (
    <Fragment key={i}>
      {line}
      {i < a.length - 1 ? <br /> : null}
    </Fragment>
  ));
}

export const sendContactMailFromForm = (form: HTMLFormElement) => {
  emailjs
    .sendForm('service_yli3szk', 'template_1a6zr6r', form, 'xEh7zVU5TZIx8hIoL')
    .then(
      (result: any) => {
        console.log(result.text);
      },
      (error: any) => {
        console.log(error.text);
      }
    );
  form.reset();
};
