import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    background: {
      default: '#191919',
    },
    primary: {
      main: '#004163',
    },
    mode: 'dark',
  },
  typography: {
    fontFamily: 'Poppins',
  },
});

theme.typography.body1 = {
  ...theme.typography.body1,
  fontSize: theme.typography.pxToRem(14),
};

theme.typography.h2 = {
  ...theme.typography.h2,
  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.pxToRem(40),
  },
};

theme.typography.h4 = {
  ...theme.typography.h4,
  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.pxToRem(20),
  },
};

export default theme;
