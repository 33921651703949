import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const Nederlands: FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 9 6"
    sx={{
      height: '1em',
      width: 'auto',
      ...props.sx,
    }}
  >
    <rect fill="#21468B" width="9" height="6" />
    <rect fill="#FFF" width="9" height="4" />
    <rect fill="#AE1C28" width="9" height="2" />
  </SvgIcon>
);

export default Nederlands;
