import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const Send: FC<SvgIconProps> = ({ sx = {}, ...props }) => (
  <SvgIcon
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23.81 23.81"
    sx={(theme) => ({
      height: '1em',
      width: 'auto',
      ...(typeof sx === 'function' ? sx(theme) : (sx as any)),
    })}
  >
    <path
      id="send"
      d="M23.437.146A.83.83,0,0,1,23.8,1L20.394,21.4a.836.836,0,0,1-.425.6.814.814,0,0,1-.412.106.9.9,0,0,1-.319-.066L13.22,19.585,10,23.5a.783.783,0,0,1-.651.306.722.722,0,0,1-.292-.053.85.85,0,0,1-.558-.8V18.322L19.982,4.251l-14.2,12.29L.531,14.39a.841.841,0,0,1-.106-1.515L22.533.12A.811.811,0,0,1,22.958,0a.824.824,0,0,1,.478.146Z"
      transform="translate(0.002)"
      fill="currentColor"
    />
  </SvgIcon>
);

export default Send;
