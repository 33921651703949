import { FC, ReactNode } from 'react';
import { Box } from '@mui/material';

export interface LegendProps {
  color: string;
  children: ReactNode;
}

const Legend: FC<LegendProps> = ({ color, children }) => (
  <Box
    sx={(theme) => ({
      display: 'flex',
      alignItems: 'center',
      '&:before': {
        content: '""',
        display: 'block',
        height: 10,
        width: 80,
        backgroundColor: color,
        mr: 2.5,
        flexShrink: 0,
        [theme.breakpoints.down('md')]: {
          width: 50,
        },
      },
    })}
  >
    {children}
  </Box>
);

export default Legend;
