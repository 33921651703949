import { createRef, FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { leToBr } from '../../utils';
import GlowingTitle from '../common/GlowingTitle';
import bnbImg1 from '../../assets/images/bnb-img1.jpeg';
import InView from '../common/InView';

const BnBSection: FC<SectionProps> = ({ onEnter }) => {
  const { t } = useTranslation();
  const bnbRef = createRef<HTMLElement>();

  return (
    <InView targetRef={bnbRef} onEnter={() => onEnter?.('bnb')}>
      <Stack
        id="bnb"
        ref={bnbRef}
        direction="row"
        component="section"
        sx={(theme) => ({
          backgroundColor: '#FFFFFF08',
          mt: 16,
          mb: 6,
          '& #bnb-img1': {
            mt: -10,
            mb: 2,
            maxWidth: '50%',
            [theme.breakpoints.down('lg')]: {
              m: -4,
              maxWidth: 'none',
            },
          },
          [theme.breakpoints.down('lg')]: {
            p: 4,
            mt: 0,
            mb: 2,
            flexDirection: 'column',
          },
        })}
      >
        <img id="bnb-img1" src={bnbImg1} alt="House with terrace" />
        <Box
          sx={(theme) => ({
            pl: 16.25,
            pr: 4,
            maxWidth: 692,
            mb: 4,
            [theme.breakpoints.down('lg')]: {
              p: 0,
              maxWidth: 'none',
            },
          })}
        >
          <GlowingTitle
            sx={(theme) => ({
              textTransform: 'uppercase',
              mt: 4,
              mb: 6,
              [theme.breakpoints.down('lg')]: {
                mt: 0,
                mb: 2,
              },
            })}
          >
            {t('home.bnb.title')}
          </GlowingTitle>
          <Typography sx={{ mb: 4 }}>
            {leToBr(t('home.bnb.paragraph-one'))}
          </Typography>
          <Typography
            variant="h6"
            component="p"
            sx={(theme) => ({
              '& em': {
                fontStyle: 'normal',
                color: 'white',
                fontWeight: 'bold',
              },
            })}
          >
            <Trans i18nKey="home.bnb.pricing">
              B&B: <em>49€/night</em>
              <br />
              Airport: <em>40€</em>
            
            </Trans>
          </Typography>
        </Box>
      </Stack>
    </InView>
  );
};

export default BnBSection;
