import React, { FC, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import InView from '../common/InView';
import GlowingTitle from '../common/GlowingTitle';
import { useTranslation } from 'react-i18next';
import { leToBr } from '../../utils';
import Popup from '../common/Popup'; // Assurez-vous que le chemin d'importation est correct
import heroBackground from '../../assets/images/baobab.jpeg';

const HomeSection: FC<SectionProps> = ({ onEnter }) => {
  const { t } = useTranslation();
  const homeRef = useRef<HTMLElement>(null);
  const [popupOpen, setPopupOpen] = useState(false); // Le pop-up est ouvert initialement

  return (
    <>
      <Popup open={popupOpen} onClose={() => setPopupOpen(false)} />
      {/* Affiche le pop-up */}
      <InView targetRef={homeRef} onEnter={() => onEnter?.('homepage')}>
        <Box
          component="section"
          id="homepage"
          ref={homeRef}
          sx={{
            minHeight: '100vh',
            position: 'relative',
            overflow: 'hidden',
            '&::before': {
              content: "''",
              zIndex: -1,
              display: 'block',
              position: 'absolute',
              width: '100%',
              height: '100%',
              backgroundImage: `url(${heroBackground})`,
              backgroundSize: 'cover',
            },
          }}
        >
          <Box
            sx={(theme) => ({
              [theme.breakpoints.down('lg')]: {
                p: 2,
              },
            })}
          >
            <Box
              sx={(theme) => ({
                width: 'clamp(0px, 800px, 90%)',
                mt: 25,
                backgroundColor: `${theme.palette.primary.main}bb`,
                position: 'absolute',
                left: '50%',
                bottom: theme.spacing(2),
                p: 6.25,
                transform: 'translateX(-50%)',
                [theme.breakpoints.down('lg')]: {
                  position: 'static',
                  width: '100%',
                  px: 4,
                  transform: 'none',
                  mt: 14,
                },
              })}
            >
              <GlowingTitle
                sx={(theme) => ({
                  mb: 2,
                  mt: -12,
                  textTransform: 'uppercase',
                  [theme.breakpoints.down('md')]: {
                    mt: -10,
                  },
                })}
              >
                {t('home.title')}
              </GlowingTitle>
              <Typography>{leToBr(t('home.paragraph-one'))}</Typography>
            </Box>
          </Box>
        </Box>
      </InView>
    </>
  );
};

export default HomeSection;
