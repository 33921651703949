import { FC, FormEvent } from 'react';
import {
  Box,
  Button,
  Container,
  createTheme,
  Divider,
  Grid,
  Link,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material';
import GlowingTitle from '../common/GlowingTitle';
import { useTranslation } from 'react-i18next';
import { leToBr, sendContactMailFromForm } from '../../utils';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import SendIcon from '../icons/Send';
import makiImg from '../../assets/images/maki.jpeg';
import sunsetPalmTreeImg from '../../assets/images/sunset-palm-tree.jpeg';

const ContactSection: FC = () => {
  const { t } = useTranslation();

  return (
    <Container
      id="contact"
      component="section"
      maxWidth="xl"
      sx={(theme) => ({
        my: 20,
        [theme.breakpoints.down('md')]: {
          mb: 8,
          mt: 12,
        },
      })}
    >
      <Grid container direction="row" spacing={{ lg: 16, md: 8, xs: 4 }}>
        <Grid item xs={12} md={6}>
          <GlowingTitle sx={{ textTransform: 'uppercase' }}>
            {t('home.contact.title')}
          </GlowingTitle>
          <Divider
            sx={{
              width: 260,
              borderBottomWidth: 4,
              borderColor: 'white',
              mb: 4,
            }}
          />
          <ThemeProvider
            theme={createTheme({
              palette: {
                primary: {
                  main: '#0095B4',
                },
                mode: 'dark',
              },
            })}
          >
            <Stack
              component="form"
              direction="column"
              alignItems="flex-start"
              spacing={2}
              onSubmit={(e: FormEvent<HTMLFormElement>) => {
                e.preventDefault();
                sendContactMailFromForm(e.currentTarget);
              }}
            >
              <TextField
                fullWidth
                label={t('home.contact.name')}
                variant="standard"
                name="name"
              />
              <TextField
                fullWidth
                label={t('home.contact.email')}
                variant="standard"
                name="email"
              />
              <TextField
                fullWidth
                label={t('home.contact.number')}
                variant="standard"
                name="number"
              />
              <TextField
                multiline
                fullWidth
                rows={5}
                label={t('home.contact.message')}
                variant="standard"
                name="message"
              />

              <Button
                variant="contained"
                color="primary"
                type="submit"
                startIcon={<SendIcon />}
                size="large"
                sx={{ textTransform: 'none', mt: 3 }}
              >
                {t('home.contact.send')}
              </Button>
            </Stack>
          </ThemeProvider>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            '&>.leaflet-container': {
              display: 'block',
              width: '100%',
              aspectRatio: '762/493',
            },
          }}
        >
          <MapContainer
            center={[-18.9776191, 47.5306986]}
            zoom={16}
            scrollWheelZoom={true}
            style={{ width: '100%', height: '100%' }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={[-18.9775287, 47.5316979]}>
              <Popup>
                Cinéroutes <br /> LOT IB 237 Andoharanofotsy- Antananarivo 102 -
                Madagascar
              </Popup>
            </Marker>
            <Marker position={[-18.9776268, 47.5328735]}>
              <Popup>{t('home.firstGate')}</Popup>
            </Marker>
          </MapContainer>
          <Link
            sx={{ mt: 2, color: 'white' }}
            href="https://goo.gl/maps/3gF1bwVUEHdgVcGT9"
            target="blank"
          >
            https://goo.gl/maps/3gF1bwVUEHdgVcGT9
          </Link>
          <Link
            sx={{ color: 'white' }}
            href="https://goo.gl/maps/oWBkd66TNWNKoEk36"
            target="blank"
          >
            https://goo.gl/maps/oWBkd66TNWNKoEk36
          </Link>
        </Grid>
      </Grid>

      <Stack direction="column" sx={{ mt: 8, maxWidth: 600, mx: 'auto' }}>
        <Typography gutterBottom>
          {leToBr(t('home.contact.credits'))}
        </Typography>
        <Stack
          direction="row"
          justifyContent="flex-start"
          sx={{ '&>img': { width: '50%' } }}
        >
          <Box component="img" src={makiImg} alt="maki" />
          <Box
            component="img"
            src={sunsetPalmTreeImg}
            alt="Palm tree in the sunset"
          />
        </Stack>
      </Stack>
    </Container>
  );
};

export default ContactSection;
