import { FC, ReactNode } from 'react';
import { SxProps, Theme, Typography } from '@mui/material';

export interface GlowingTitleProps {
  children: ReactNode;
  sx?: SxProps<Theme>;
  glowingBackgroundSx?: SxProps<Theme>;
}

const GlowingTitle: FC<GlowingTitleProps> = ({
  sx = {},
  children,
  glowingBackgroundSx = {},
}) => (
  <Typography
    component="h1"
    variant="h2"
    sx={(theme) => ({
      width: 'fit-content',
      fontWeight: 'bold',
      py: 1,
      position: 'relative',
      ...(typeof sx === 'function' ? sx(theme) : (sx as any)),
      '&::before': {
        zIndex: -1,
        content: '""',
        display: 'block',
        width: 60,
        height: 160,
        backgroundColor: '#0095B4',
        filter: 'blur(32px)',
        position: 'absolute',
        left: '50%',
        top: '50%',
        opacity: 0.8,
        transform: 'translate(-50%, -50%)',
        ...(typeof glowingBackgroundSx === 'function'
          ? glowingBackgroundSx(theme)
          : (glowingBackgroundSx as any)),
      },
    })}
  >
    {children}
  </Typography>
);

export default GlowingTitle;
