/**
 * Native scrollTo with promise support for better async/await usage
 * @param options Options passed to the scrollTo function
 */
export function scrollTo(options: ScrollToOptions): Promise<void> {
  return new Promise((res, rej) => {
    const onScroll: (this: Window, ev: Event) => any = function () {
      const top = options.top,
        left = options.left;

      if (!top && !left)
        return rej(new Error('No top or left scroll value specified'));

      if (
        this.scrollY.toFixed() ===
        (options.top?.toFixed() || document.body.offsetHeight)
      ) {
        // Scroll is completed, resolve the promise
        this.removeEventListener('scroll', onScroll);
        res();
      }
    };
    window.addEventListener('scroll', onScroll);
    window.scrollTo(options);
  });
}
