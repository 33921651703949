import { createRef, FC, ReactNode } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { leToBr } from '../../utils';
import GlowingTitle from '../common/GlowingTitle';
import InView from '../common/InView';
import baobabAlleyImg from '../../assets/images/baobab-alley.jpeg';
import CarInMudImg from '../../assets/images/4WD-in-the-mud.jpeg';
import renault4Img from '../../assets/images/renault-4.jpeg';
import rockyMassifsImg1 from '../../assets/images/rocky-massifs-1.jpeg';
import rockyMassifsImg2 from '../../assets/images/rocky-massifs-2.jpeg';
import palmBeachImg from '../../assets/images/palm-beach.jpeg';
import rockyRiverImg from '../../assets/images/rocky-river.jpeg';
import mapImg from '../../assets/images/map.png';
import Legend from '../common/Legend';

interface RouteSectionTitleProps {
  decorationColor: string;
  children: ReactNode;
}

const RouteSectionTitle: FC<RouteSectionTitleProps> = ({
  decorationColor,
  children,
}) => (
  <Typography
    variant="h5"
    sx={(theme) => ({
      backgroundColor: '#ffffff12',
      position: 'relative',
      pl: 28.5,
      pr: 4,
      py: 3,
      ml: 1.5,
      mb: 8,
      fontWeight: 'bold',
      [theme.breakpoints.down('lg')]: {
        ml: 0,
        pl: 14,
      },
      [theme.breakpoints.down('md')]: {
        pl: 4,
      },
      '&:before': {
        position: 'absolute',
        content: '""',
        display: 'block',
        backgroundColor: decorationColor,
        height: 24,
        width: 100,
        left: 80,
        top: 0,
        transform: 'translateY(-50%)',
        [theme.breakpoints.down('lg')]: {
          left: 'auto',
          right: 80,
        },
      },
    })}
  >
    {children}
  </Typography>
);

interface RouteListItemProps {
  decorationColor: string;
  title: string;
  children: ReactNode;
}

const RouteListItem: FC<RouteListItemProps> = ({
  decorationColor,
  title,
  children,
}) => (
  <Box
    sx={(theme) => ({
      pl: 30,
      pr: 4,
      mb: 6,
      [theme.breakpoints.down('lg')]: {
        px: 14,
      },
      [theme.breakpoints.down('md')]: {
        px: 4,
      },
    })}
  >
    <Typography
      variant="h5"
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
        mb: 2.5,
        '&:before': {
          content: '""',
          display: 'inline-block',
          width: 80,
          height: 5,
          backgroundColor: decorationColor,
          mr: 2,
          ml: -12,
          [theme.breakpoints.down('lg')]: {
            ml: -6,
            width: 32,
          },
        },
      })}
    >
      {title}
    </Typography>
    {children}
  </Box>
);

const RoutesSection: FC<SectionProps> = ({ onEnter }) => {
  const { t } = useTranslation();
  const routesRef = createRef<HTMLElement>();

  return (
    <InView targetRef={routesRef} onEnter={() => onEnter?.('routes')}>
      <Grid
        ref={routesRef}
        id="routes"
        container
        component="section"
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={4}
        sx={(theme) => ({ my: 4, [theme.breakpoints.down('md')]: { my: 2 } })}
      >
        <Grid item xs={12} lg={7}>
          <Box
            sx={(theme) => ({
              pl: 30,
              mb: 4,
              [theme.breakpoints.down('lg')]: {
                px: 14,
              },
              [theme.breakpoints.down('md')]: {
                px: 4,
              },
            })}
          >
            <GlowingTitle
              sx={{
                mb: 3,
                textTransform: 'uppercase',
              }}
              glowingBackgroundSx={(theme) => ({
                [theme.breakpoints.down('md')]: {
                  height: 50,
                },
              })}
            >
              {t('home.routes.title')}
            </GlowingTitle>
            <Box
              component="img"
              src={baobabAlleyImg}
              sx={{ width: '100%', marginBottom: '2rem' }}
              alt="Baobab alley"
            />
            <Typography sx={{ mb: 2.5 }}>
              {leToBr(t('home.routes.paragraph-one'))}
            </Typography>
            <Typography>{leToBr(t('home.routes.paragraph-two'))}</Typography>
          </Box>

          <RouteSectionTitle decorationColor="#0095B4">
            RENAULT 4 or 4x4
          </RouteSectionTitle>
          <Box
            component="img"
            src={renault4Img}
            alt="Renault 4"
            sx={(theme) => ({
              pl: 30,
              mb: 4,
              width: '100%',
              [theme.breakpoints.down('lg')]: {
                px: 14,
              },
              [theme.breakpoints.down('md')]: {
                px: 4,
              },
            })}
          />
          <Typography
            sx={(theme) => ({
              pl: 30,
              mb: 4,
              [theme.breakpoints.down('lg')]: {
                px: 14,
              },
              [theme.breakpoints.down('md')]: {
                px: 4,
              },
            })}
          >
            {t('home.routes.hint')}
          </Typography>
          <RouteListItem
            title={t('home.routes.southbound-rn7-tulear.title')}
            decorationColor="#0095B4"
          >
            <Typography sx={{ mb: 2.5 }}>
              {leToBr(t('home.routes.southbound-rn7-tulear.paragraph-one'))}
            </Typography>
            <Typography>
              {leToBr(t('home.routes.southbound-rn7-tulear.paragraph-two'))}
            </Typography>
          </RouteListItem>
          <RouteListItem
            title={t('home.routes.eastbound-rn2-tamatave.title')}
            decorationColor="#0095B4"
          >
            <Typography sx={{ mb: 2.5 }}>
              {leToBr(t('home.routes.eastbound-rn2-tamatave.paragraph-one'))}
            </Typography>
            <Typography>
              {leToBr(t('home.routes.eastbound-rn2-tamatave.paragraph-two'))}
            </Typography>
          </RouteListItem>
          <RouteListItem
            title={t('home.routes.westbound-rn7/rn34-35-morondava.title')}
            decorationColor="#0095B4"
          >
            <Typography>
              {leToBr(
                t('home.routes.westbound-rn7/rn34-35-morondava.description')
              )}
            </Typography>
          </RouteListItem>
          
          <RouteListItem
            title={t('home.routes.northbound-rn4-mahajanga.title')}
            decorationColor="#0095B4"
          >
            <Typography>
              {t('home.routes.northbound-rn4-mahajanga.description')}
            </Typography>
          </RouteListItem>

          <RouteListItem
            title={t('home.routes.north-rn6.title')}
            decorationColor="#0095B4"
          >
            
            <Typography sx={{ '& b': { color: 'red' } }}>
              <Trans i18nKey="home.routes.north-rn6.description" />
              
            </Typography>
          </RouteListItem>

          <RouteSectionTitle decorationColor="#FF9E1E">
            {t('home.routes.onlyOn4x4')}
          </RouteSectionTitle>
          <Box
            component="img"
            src={CarInMudImg}
            alt="4x4 in the mud"
            sx={(theme) => ({
              pl: 30,
              mb: 4,
              width: '100%',
              [theme.breakpoints.down('lg')]: {
                px: 14,
              },
              [theme.breakpoints.down('md')]: {
                px: 4,
              },
            })}
          />
          <RouteListItem
            title={t('home.routes.westbound-rn7/rn34-35-morondava.title')}
            decorationColor="#FF9E1E"
          >
            <Typography sx={{ mb: 2.5, '& b': { color: 'red' } }}>
              <Trans i18nKey="home.routes.westbound-rn7/rn34-35-morondava.only-4x4-description" />
            </Typography>
          </RouteListItem>
          <RouteListItem
            title={t('home.routes.southern-madagascar.title')}
            decorationColor="#FF9E1E"
          >
            <Typography sx={{ mb: 2.5, '& b': { color: 'red' } }}>
              <Trans i18nKey="home.routes.southern-madagascar.description" />
            </Typography>
          </RouteListItem>
        </Grid>
        <Grid item xs={12} lg={5}>
          <Box
            component="img"
            src={rockyMassifsImg1}
            alt="Rocky massifs"
            sx={(theme) => ({
              mb: 4,
              width: '100%',
              pr: 30,
              [theme.breakpoints.down('xl')]: {
                pr: 8,
              },
              [theme.breakpoints.down('lg')]: {
                pr: 4,
                pl: 4,
              },
            })}
          />

          <Box
            component="img"
            src={rockyMassifsImg2}
            alt="Rocky massifs"
            sx={(theme) => ({
              mb: 4,
              width: '100%',
              pr: 30,
              [theme.breakpoints.down('xl')]: {
                pr: 8,
              },
              [theme.breakpoints.down('lg')]: {
                pr: 4,
                pl: 4,
              },
            })}
          />

          <Box
            sx={(theme) => ({
              pr: 30,
              [theme.breakpoints.down('xl')]: {
                pr: 8,
              },
              [theme.breakpoints.down('lg')]: {
                pr: 4,
                pl: 4,
              },
              '& #map': {
                display: 'block',
                maxWidth: '90%',
                [theme.breakpoints.down('lg')]: {
                  mx: 'auto',
                },
              },
            })}
          >
            <Stack direction="column" spacing={2} sx={{ mb: 2 }}>
              <Legend color="#FFFF00">
                {t('home.routes.legend.renault4-4x4')}
              </Legend>
              <Legend color="#0070C0">
                {t('home.routes.legend.renault4NoRainySeason')}
              </Legend>
              <Legend color="#C00000">
                {t('home.routes.legend.possibleOnlyOutsideRainySeason')}
              </Legend>
            </Stack>

            <img id="map" src={mapImg} alt="Madagascar map" />
          </Box>
          <Box
            component="img"
            src={rockyRiverImg}
            alt="palm beach"
            sx={(theme) => ({
              mt: 4,
              mb: 4,
              width: '100%',
              pr: 30,
              [theme.breakpoints.down('xl')]: {
                pr: 8,
              },
              [theme.breakpoints.down('lg')]: {
                pr: 4,
                pl: 4,
              },
            })}
          />
          <Box
            component="img"
            src={palmBeachImg}
            alt="palm beach"
            sx={(theme) => ({
              mb: 4,
              width: '100%',
              pr: 30,
              [theme.breakpoints.down('xl')]: {
                pr: 8,
              },
              [theme.breakpoints.down('lg')]: {
                pr: 4,
                pl: 4,
              },
            })}
          />
        </Grid>
      </Grid>
    </InView>
  );
};

export default RoutesSection;
