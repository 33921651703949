import { FC, useCallback } from 'react';
import 'leaflet/dist/leaflet.css';
import HomeSection from '../components/partials/HomeSection';
import BnBSection from '../components/partials/BnBSection';
import CarRentalSection from '../components/partials/CarRentalSection';
import RoutesSection from '../components/partials/RoutesSection';
import ContactSection from '../components/partials/ContactSection';
import Footer from '../components/common/Footer';
import Renault4 from '../components/partials/Renault4';

export interface HomePageProps {
  activeSectionId?: string;
  setActiveSectionId?: (activeSectionId: string) => void;
  scrolling: boolean;
}

const HomePage: FC<HomePageProps> = ({
  activeSectionId,
  setActiveSectionId,
  scrolling,
}) => {
  const onEnter = useCallback(
    (id: string) => {
      if (!scrolling && id !== activeSectionId) {
        setActiveSectionId?.(id);
      }
    },
    [activeSectionId, scrolling, setActiveSectionId]
  );

  return (
    <>
      <HomeSection {...{ onEnter }} />
      <BnBSection {...{ onEnter }} />
      <CarRentalSection {...{ onEnter }} />
      <Renault4 {...{ onEnter }} />
      <RoutesSection {...{ onEnter }} />
      <ContactSection />
      <Footer />
    </>
  );
};

export default HomePage;
