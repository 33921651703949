export function fallbackCopyTextToClipboard(text: string): Promise<void> {
  return new Promise((res, rej) => {
    var textArea = document.createElement('textarea');
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
      res();
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
      rej(err);
    }

    document.body.removeChild(textArea);
  });
}

export function copyTextToClipboard(text: string): Promise<void> {
  if (!navigator.clipboard) {
    return fallbackCopyTextToClipboard(text);
  }

  return new Promise((res, rej) => {
    navigator.clipboard.writeText(text).then(
      function () {
        console.log('Async: Copying to clipboard was successful!');
        res();
      },
      function (err) {
        console.error('Async: Could not copy text: ', err);
        rej(err);
      }
    );
  });
}
